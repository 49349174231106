import React from 'react'
import { graphql } from 'gatsby'
import slugify from 'slugify'
import Layout from '../../layout'

export default (props) => {
  const { data: { markdownRemark: { frontmatter: { blocks, heroImage, title, seo_description } } } } = props
  const header = { template: 'blog-header', image: heroImage, title: title }
  return (
    <Layout location={props.location} author='null' title={title} description={seo_description} image={heroImage} slug={slugify(title).toLowerCase()} components={[header, ...blocks]} />
  )
}

export const pageQuery = graphql`
  query ($id: String) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        seo_description
        blocks {
          description
          paragraphs
          template
          url
          imageSmall
          imageCarousel {
            description
            image
          }
        }
        heroImage
        title
      }
    }
  }
`
